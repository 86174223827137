import React, { useEffect, useState } from "react";
import { useItemsFine } from "../actions/app/items";
import log from "cslog";
import { getPublicImageURL } from "../_helpers/image";


function CreationsSelection({ value, onChange }) {
    const [ids_to_fetch, setIdsToFetch] = useState([]);
    const [creation_ids_to_fetch, setCreationIdsToFetch] = useState([]);

    const { isSuccess, isLoading, data } = useItemsFine(
        "qdb_creation_template",
        `fields=id,title,preview&sort=-date_created&filter[id][_in]=${ids_to_fetch?.length > 0 ? ids_to_fetch.join(",") : ""}`,
        ids_to_fetch && ids_to_fetch.length > 0
    )
    const { isSuccess: successCreations, isLoading: loadingCreations, data: dataCreations } = useItemsFine(
        "qp_creation",
        `fields=id,title,preview&sort=-date_created&filter[id][_in]=${creation_ids_to_fetch?.length > 0 ? creation_ids_to_fetch.join(",") : ""}`,
        creation_ids_to_fetch && creation_ids_to_fetch.length > 0
    )


    useEffect(() => {
        if (value && value.length > 0) {
            if (value.some(item => ids_to_fetch.indexOf(item.id) === -1)) {
                // setIdsToFetch(value.map(item => item.id));
                setIdsToFetch(value.filter(item => item?.collection === "qdb_creation_template").map(item => item.id));
                setCreationIdsToFetch(value.filter(item => item?.collection === "qp_creation").map(item => item.id));
            }
        }
    }, [value])




    log.d(value, "value in CreationSelection")

    log.d(dataCreations, "dataCreations in CreationSelection");
    log.d(data, "dataTemplates in CreationSelection");


    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "10px",
            paddingTop: "15px",
            paddingBottom: "15px"
        }}>
            {/* {(isSuccess || successCreations) && value?.length > 0 && value.map(item => { */}
            {value?.length > 0 && value.map(item => {
                let creation = null;
                if (item?.collection === "qdb_creation_template") {
                    creation = data?.find(creation => creation.id === item.id)
                } else if (item?.collection === "qp_creation") {
                    creation = dataCreations?.find(creation => creation.id === item.id)
                } else {
                    creation = item;
                }

                return <div
                    style={{
                        width: "150px",
                        height: "150px",
                        border: "1px solid #d9d9d9",
                        borderRadius: "5px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        backgroundColor: "#f4f1f1",
                        outline: "1px solid transparent",
                        position: "relative",
                        overflow: "hidden",
                    }}
                >
                    {/* <h1>{creation?.title}</h1> */}
                    <img src={getPublicImageURL(creation?.preview)}
                        style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "contain"
                        }}
                    />
                    <div style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        cursor: "pointer",
                        backgroundColor: "red",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontWeight: "bold",
                    }}
                        onClick={() => onChange(value.filter(item => item.id !== creation.id))}
                    >x</div>
                </div>
            })}
        </div>
    );
}

export default CreationsSelection;