import styles from "./styles/NewHome.module.css";
import React, { useState } from "react";
import QurealSection from "../common/QurealSection";
import { LOGO_ONLY, LOGO_SQUARE } from "../constants";
import QurealLightCore from "../creations/QurealLightCore";
import useMedia from "react-use/lib/useMedia";
import { getImageFileURL } from "../_helpers/image";


function NewHome({ me }) {
    const isMobile = useMedia("(max-width: 700px)");
    const [selected_item, setSelectedItem] = useState(null);
    const [open_preview, setOpenPreview] = useState(false);
    const [prefill, setPrefill] = useState(null);



    return <QurealSection>
        <div className={styles.body_wrapper}>
            <div className={styles.main_box}>
                {/* <p>Something about current plan</p> */}
                <div className={styles.header_wrapper}>
                    <img
                        alt="Logo"
                        // src={theme === "dark" ? LogoDark : LogoLight}
                        src={LOGO_SQUARE}
                        className={styles.logo_square}
                    />
                    <p className={styles.greeting_text}>{getCurrentGreeting()}, Shivam</p>
                </div>
                <div>
                    <QurealLightCore
                        isMobile={isMobile}
                        open={true}
                        setOpen={() => { }}
                        selected_item={selected_item}
                        setSelectedItem={setSelectedItem}
                        open_preview={open_preview}
                        setOpenPreview={setOpenPreview}
                        me={me}
                        prefill={prefill}
                    />
                </div>
                {/* <p>Niche ki line</p> */}
            </div>

            <SuggestionsBar
                suggestions={[
                    "Create gandhi jayanti post",
                    "Post for 3 tip to improve sleep",
                    "Navy day post",
                    "Promotion post for my physics coaching class",
                ]}
                onClick={(sug) => {
                    setPrefill(sug);
                }}
            />

            <div className={styles.bottom_bar}>
                <ActionBox title="Content Calendar" content="Generate full month social media content within seconds." img={getImageFileURL("8c95fa3f-9afe-4c30-9367-2f5fdb00d9ca.jpg")} />
                <ActionBox title="Festival Calendar" content="Just open and share every festival without any hassle." img={getImageFileURL("eb6ce54c-2407-4357-8742-9b7cda3f7794.avif")} />
                <ActionBox title="Create New" content="Create new content from scratch." />
                <ActionBox title="Brand Assets" content="Get your brand assets for different digital platforms." />
                <ActionBox title="Business Suit" content="Collection of relevent creations for your brand." img={getImageFileURL("ef801011-6dc6-4d19-b354-81b6870f1edd.png")} />
            </div>
        </div>
    </QurealSection>
}

export default NewHome;


function getCurrentGreeting() {
    const time = new Date().getHours();
    if (time < 12) {
        return "Good Morning";
    }
    else if (time < 18) {
        return "Good Afternoon";
    }
    else {
        return "Good Evening";
    }
}

function ActionBox({ title, content, img }) {
    return <div className={styles.action_box} style={{
        // backgroundImage: `url(${img})`
    }}>
        <h3>{title}</h3>
        <p>{content}</p>
    </div>
}


function SuggestionsBar({ suggestions = [], onClick }) {
    return <div className={styles.suggestions_bar}>
        {suggestions.map(sug => <div className={styles.suggestion} onClick={() => onClick(sug)}>{sug}</div>)}
    </div>
}