import styles from "./styles/QurealApp.module.css";
import log from "cslog";
import { useAtom } from "jotai";
import React, { Fragment, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import useMedia from "react-use/lib/useMedia";
import ComponentPopup from "../components/popup/ComponentPopup";
import QComponent from "../components/QComponent";
import { MAINBAR_WIDTH, SIDEBAR_WIDTH, TOPBAR_HEIGHT } from "../constants";
import QMade from "../qmade";
import { navAtom } from "../states/qcon";
import CRUDScreens from "./CRUDScreens";
import ItemScreen from "./ItemScreen";
import QurealScreen from "./QurealScreen";
import QurealSidebar from "./QurealSidebar";
import QBuilt from "../qbuilt";
import { sidebarAtom } from "../states/navigation";
import { Drawer } from "antd";
import QurealSidebarMobile from "./QurealSidebarMobile";

function QurealApp({ app, me = {} }) {
    const isMobile = useMedia("(max-width: 700px)");

    const [dscreens, setDScreens] = useState({});
    const [nav, setNav] = useAtom(navAtom);
    log.d(app, "App Data");

    useEffect(() => {
        if (app.id !== nav.app) {
            setNav({
                app: app.id,
            });
        }
    }, [app]);

    // const is_topbar = app.layout.navigation.type === "topbar";
    const is_topbar = false;

    const marginTop = app.layout.navigation.type === "topbar" ? "50px" : 0;
    const width = app.layout.navigation.type === "topbar" ? "auto" : "100%";

    const onDynamicScreenLoad = (data) => {
        log.d(data, "Dynamic Screen Data in App");
        const temp = [];
        Object.keys(data).map((key) => {
            if (!(key in dscreens)) {
                const dscreen = app.screens.find(
                    (item) => `id_${item.id}` === key
                );
                log.d(dscreen, "DScreen");
                const items = data[key];
                const out = [];
                items.forEach((item) => {
                    out.push({
                        id: item.id,
                        name: item[dscreen.layout.screen_name],
                        icon: item[dscreen.layout.screen_icon],
                        slug: item[dscreen.layout.screen_slug],
                    });
                });
                // temp[key] = out;
                temp[key] = {
                    screen_id: dscreen.id,
                    slug: dscreen.slug,
                    items: out,
                };
            }
        });
        log.d(temp, "TEMP");
        setDScreens({
            ...dscreens,
            ...temp,
        });
    };

    log.d(dscreens, "DScreensssss");

    const default_screen_index = app.default_screen_index;
    log.d(default_screen_index, "Default screen index");
    const defaultScreen = app.screens[default_screen_index];

    log.d(defaultScreen, "Default Screen");
    return (
        <div
            className={styles.wrapper}
            style={{
                gridTemplateColumns: is_topbar
                    ? `0 1fr`
                    : isMobile
                        ? "1fr"
                        : `${SIDEBAR_WIDTH}px 1fr`,
            }}
        >
            {/* <UsersSB /> */}
            {app.screens?.length <= 0 && <BlankApp />}

            {!isMobile && (
                <div
                    style={{
                        height: "100%",
                        backgroundColor: "cyan",
                    }}
                >
                    <QurealSidebar
                        app={app}
                        onLoad={onDynamicScreenLoad}
                        dscreens={dscreens}
                    />
                </div>
            )}

            <QurealSidebarMobile {...{ app, onDynamicScreenLoad, dscreens }} />

            <div
                style={{
                    width: width,
                    marginTop: marginTop,
                    // marginLeft: isMobile ? 0 : marginLeft,
                    overflow: "auto",
                    backgroundColor: "#F5F5F5",
                    maxHeight: `calc(100vh - ${TOPBAR_HEIGHT}px)`,
                    // maxHeight: "90vh", //for topbar
                }}
            >
                {true && (
                    <Switch>
                        {defaultScreen && (
                            <Redirect
                                exact
                                from={`/${app.slug}`}
                                to={`/${app.slug}/${defaultScreen.slug}`}
                            />
                        )}

                        <Route path={`/${app.slug}/data`}>
                            <CRUDScreens />
                        </Route>

                        <Route path={`/${app.slug}/items/:collection`}>
                            <ItemScreen />
                        </Route>

                        <Route path={`/${app.slug}/open/:component/:record`}>
                            <QComponent />
                        </Route>

                        {app.screens.map((item) => {
                            const screen_type = item.type;
                            if (item.status === "published") {
                                switch (screen_type) {
                                    case "static":
                                        return (
                                            <Route
                                                key={item.slug}
                                                // exact
                                                path={`/${app.slug}/${item.slug}`}
                                                component={() => (
                                                    <QurealScreen
                                                        screen_id={item.id}
                                                    />
                                                )}
                                            />
                                        );
                                    case "qbuilt":
                                        return (
                                            <Route
                                                key={item.slug}
                                                // exact
                                                path={`/${app.slug}/${item.slug}`}
                                                component={() => (
                                                    <QBuilt
                                                        screen={
                                                            item.layout.screen
                                                        }
                                                        me={me}
                                                    />
                                                )}
                                            />
                                        );
                                    case "qmade":
                                        return (
                                            <Route
                                                key={item.slug}
                                                // exact
                                                path={`/${app.slug}/${item.slug}`}
                                                component={() => (
                                                    // <QMade
                                                    <QBuilt
                                                        screen={
                                                            item.layout.screen
                                                        }
                                                    />
                                                )}
                                            />
                                        );
                                    default:
                                        return <></>;
                                }
                            }
                        })}

                        {/* {app.screens.map((item) => {
                            const stype = item.layout.type;
                            if (
                                stype === "qmade" &&
                                item.status === "published"
                            ) {
                                return (
                                    <Route
                                        key={item.slug}
                                        // exact
                                        path={`/${app.slug}/${item.slug}`}
                                        component={() => (
                                            <QMade
                                                screen={item.layout.screen}
                                            />
                                        )}
                                    />
                                );
                            }
                        })} */}

                        <Route
                            key="creative_edit"
                            path={`/creatives/edit/:id`}
                            component={() => <QMade screen="creative_edit" />}
                        />

                        <Route exact
                            key="org_create"
                            path={`/settings/brand/create`}
                            component={() => <QMade screen="org_create" />}
                        />

                        <Route exact
                            key="org_edit"
                            path={`/settings/brand/:id/edit`}
                            component={() => <QMade screen="org_edit" />}
                        />


                        {/* For creation collections------------------------------------------- */}
                        <Route exact
                            key="collection_create"
                            path="/settings/collections/create"
                            component={() => <QMade screen="collection_create" />}
                        />

                        <Route exact
                            key="collection_edit"
                            path="/settings/collections/:id/edit"
                            component={() => <QMade screen="collection_edit" />}
                        />
                        {/* -------------------------------------------------------------- */}

                        <Route exact
                            key="person_create"
                            path={`/settings/person/create`}
                            component={() => <QMade screen="person_create" />}
                        />

                        <Route exact
                            key="person_edit"
                            path={`/settings/person/:id/edit`}
                            component={() => <QMade screen="person_edit" />}
                        />




                        {/* {app.screens.map((item) => {
                            const stype = item.layout.type;
                            if (
                                stype === "static" &&
                                item.status === "published"
                            ) {
                                return (
                                    <Route
                                        key={item.slug}
                                        // exact
                                        path={`/${app.slug}/${item.slug}`}
                                        component={() => (
                                            <QurealScreen screen_id={item.id} />
                                        )}
                                    />
                                );
                            }
                        })} */}

                        {Object.values(dscreens).map((screen) => {
                            return screen.items.map((item) => {
                                return (
                                    <Route
                                        key={item.slug}
                                        // exact
                                        path={`/${app.slug}/${screen.slug}/:id`}
                                        // component={() => <h1>{item.name}</h1>}
                                        component={() => (
                                            <QurealScreen
                                                screen_id={screen.screen_id}
                                            />
                                        )}
                                    />
                                );
                            });
                        })}
                    </Switch>
                )}
            </div>
        </div>
    );
}

export default QurealApp;

function BlankApp() {
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                color: "#aaa",
            }}
        >
            <i
                className="fas fa-paint-roller"
                style={{
                    fontSize: "2.5rem",
                }}
            ></i>
            <h2>Coming soon...</h2>
        </div>
    );
}
