import { useQuery } from "react-query";
import { secureAxios, publicAxios } from "../../_helpers/auth";
import log from "cslog";

export const useAllFields = () => {
    return useQuery(
        "fields",
        () => secureAxios.get(`/fields`).then((res) => res?.data),
        {
            // staleTime: 1000 * 60 * 60 * 5,
        }
    );
};

// export const useFields = (collection) => {
//     const query = useAllFields();

//     log.d(query.data, "All Fields");

//     // const fields = query.data?.filter((item) => item.collection === collection);

//     return {
//         ...query,
//         data: query.data?.filter((item) => item.collection === collection),
//     };
// };

export const useFields = (collection) => {
    return useQuery(
        ["fields", collection],
        () => secureAxios.get(`/fields/${collection}`).then((res) => res?.data),
        {
            // staleTime: 1000 * 60 * 60 * 5,
        }
    );
};

export const useFieldsDirect = (collection) => {
    return useQuery(
        ["fields", collection],
        () =>
            publicAxios
                // .get(`/qe_fields/get/${collection}`)
                .get(`qe_register/get_fields/${collection}`)
                .then((res) => res?.data),
        {
            // staleTime: 1000 * 60 * 60 * 5,
        }
    );
};

