import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { restoreAccessToken } from "../_helpers/auth";
import { queryClient } from "../_helpers/query";

export const botAxios = axios.create({
    baseURL: "https://botapi.qureal.com/",
    // baseURL: "http://localhost:8000/",
    headers: {},
});


export const useBGRemove = (onSuccess, onDone = () => { }) => {
    return useMutation(
        (data) => {
            return botAxios
                // .post(`/image/remove_bg_from_name`, data, {
                .post(`/image/remove_bg_and_crop_by_name`, data, {
                    headers: {
                        Authorization: `Bearer ${restoreAccessToken()}`,
                    },
                })
                .then((res) => {
                    return res;
                });
        },
        {
            onSuccess: (result, variables, context) => {
                onSuccess(result);
                queryClient.invalidateQueries("photos");
            },
            onSettled: (data) => {
                onDone();
            },
        }
    );
};

export const useSmartCrop = (onSuccess, onDone = () => { }) => {
    return useMutation(
        (data) => {
            return botAxios
                .post(`/image/crop_from_name`, data, {
                    headers: {
                        Authorization: `Bearer ${restoreAccessToken()}`,
                    },
                })
                .then((res) => {
                    return res;
                });
        },
        {
            onSuccess: (result, variables, context) => {
                onSuccess(result);
                queryClient.invalidateQueries("photos");
            },
            onSettled: (data) => {
                onDone();
            },
        }
    );
};


export const useGenerateTemplateSuggestions = (onSuccess, onDone = () => { }) => {
    return useMutation(
        (data) => {
            return botAxios
                .post(`/gen/prompt_to_suggestions`, data, {
                    headers: {
                        Authorization: `Bearer ${restoreAccessToken()}`,
                    },
                })
                .then((res) => {
                    return res?.data;
                });
        },
        {
            onSuccess: (result, variables, context) => {
                onSuccess(result);
            }
        }
    );
}

export const useAIImageGenerator = (onSuccess, onDone = () => { }) => {
    return useMutation(
        data => {
            return botAxios.post('/image/gen', data, {
                headers: {
                    Authorization: `Bearer ${restoreAccessToken()}`,
                },
            })
        },
        {
            onSuccess: (result, variables, context) => {
                onSuccess(result?.data || {}, variables, context)
            },
            onSettled: data => {
                onDone()
            }
        }
    )
}



export const useGenerateTemplatePreset = (onSuccess, onDone = () => { }) => {
    return useMutation(
        (data) => {
            return botAxios
                // .post(`/gen/generate_template_presets`, data, {
                .post(`/gen/generate_template_presets_new`, data, {
                    headers: {
                        Authorization: `Bearer ${restoreAccessToken()}`,
                    },
                })
                .then((res) => {
                    return res?.data;
                });
        },
        {
            onSuccess: (result, variables, context) => {
                onSuccess(result);
            }
        }
    );
};

export const useAutoTagTemplate = (item, enabled) => {
    const text = `
    ${item.title}
    `
    return useQuery(
        ["ai", "tags", item?.id],
        () =>
            botAxios
                .post(`/gen/get_template_tags`, { text: text })
                .then((res) => res.data?.data),
        {
            enabled: enabled,
        }
    );
}

export const useAutoTagTemplateMutation = (onSuccess, onDone = () => { }) => {
    return useMutation(
        (data) => {
            const text = `
            ${data.title}
            `
            return botAxios
                .post(`/gen/get_template_tags`, { text: text })
                .then((res) => {
                    return res?.data?.data;
                });
        },
        {
            onSuccess: (result, variables, context) => {
                onSuccess(result);
            }
        }
    );
}