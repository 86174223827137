import log from "cslog";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { useCheckUser, useLogin } from "../actions/auth";
import GoogleLoginButton from "../auth/GoogleLoginButton";
import { history } from "../_helpers/history";
import { queryClient } from "../_helpers/query";
import CenterCard from "../_layouts/CenterCard";
import AutoLogin from "./AutoLogin";
import QUREAL_LOGO from "../media/imgs/0_logo_square.png";
import FacebookLoginButton from "../auth/FacebookLoginButton";
import { Alert, Button, Checkbox, Form, Input, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import CenterCard2Part from "../_layouts/CenterCard2Part";
import useMedia from "react-use/lib/useMedia";

const { Text } = Typography;

function useQueryParams() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LoginNew() {
    const isMobile = useMedia("(max-width: 700px)");
    const {
        register,
        handleSubmit,
        formState: { errors: errorsForm },
        // watch,
    } = useForm();
    //Try auto logging in
    const query_params = useQueryParams();
    const autologin = !(query_params.get("nocheck") === "true");
    // actual login
    const {
        loading: loadingLogin,
        success: successLogin,
        errors: errorsLogin,
        isError: errorLogin,
        login,
    } = useLogin();
    //check user email
    const [email, setEmail] = useState("aaaa");
    const {
        isLoading: loadingCU,
        status: statusCU,
        data: dataCU,
    } = useCheckUser(email, errorLogin && email !== undefined);
    const [current_provider, setCurrentProvider] = useState("credentials");
    const [provider, setProvider] = useState(
        query_params.get("provider") || "both"
    );
    const [error, setError] = useState(query_params.get("error"));
    const [info, setInfo] = useState(false);

    log.p("Welcome to Login page");
    log.d(dataCU, "Data CU");
    log.d(email, "Email");

    // useEffect(() => {
    //     if (errorLogin && errorsLogin?.length > 0) {
    //         setError(errorsLogin.map((it) => it.message).join(", "));
    //     }
    // }, [errorsLogin, errorLogin])

    useEffect(() => {
        if (errorLogin && errorsLogin?.length > 0) {
            if (statusCU === "success" && dataCU) {
                if (dataCU.error) {
                    const err_code = dataCU.code;
                    if (err_code === "NOT_EXISTS") {
                        setError("User does not exist");
                        return;
                    }
                }
            }
            setError(errorsLogin.map((it) => it.message).join(", "));
        }
    }, [errorsLogin, errorLogin, statusCU, dataCU]);

    const sendHome = () => {
        const { from } = history.location.state || {
            from: { pathname: "/" },
        };
        history.push(from);
    };

    // useEffect(() => {
    //     const subscription = watch((value, { name, type }) => {
    //         console.log(value);
    //         setEmail(value.email);
    //     });
    //     return () => subscription.unsubscribe();
    // }, [watch]);

    useEffect(() => {
        queryClient.invalidateQueries("user");
    }, []);

    useEffect(() => {
        if (successLogin && !loadingLogin) {
            log.p("Sending home after login");
            sendHome();
        }
    }, [successLogin, loadingLogin]);



    useEffect(() => {
        if (statusCU === "success" && dataCU) {
            if (dataCU.error) {
                const err_code = dataCU.code;
                if (err_code === "NOT_EXISTS") {
                    setError("User does not exist");
                }
            } else {
                if (dataCU.provider !== current_provider) {
                    setError(false);
                    setInfo(
                        `${email} has used ${dataCU.provider} to login. please try ${dataCU.provider} Login`
                    );
                    setProvider(dataCU.provider);
                } else if (dataCU.status !== "active") {
                    setError("User is not active");
                } else {
                    setError("Invalid user credentials");
                }
            }
        }
    }, [statusCU, provider, dataCU]);

    return (
        <CenterCard2Part
            title="Welcome to Qureal AI"
            // title="Log In"
            logo={QUREAL_LOGO}
            center={false}
        >
            <AutoLogin enable={autologin}>
                <Form
                    name="normal_login"
                    style={{
                        maxWidth: isMobile ? "400px" : "300px",
                        width: isMobile ? "100%" : "auto",
                    }}
                    initialValues={{ remember: true }}
                    onFinish={(values) => {
                        const mobile = values.mobile;
                        let email = mobile;
                        if (!email.includes("@")) {
                            email = `91${email}@qureal.ai`;
                        }
                        setEmail(email);
                        login({
                            email: email,
                            password: values.password,
                        });
                    }}
                >
                    {/* {error && (
                        <Alert
                            type="error"
                            showIcon
                            message={error}
                            style={{
                                marginBottom: "10px",
                            }}
                        />
                    )} */}
                    {error && <Text type="danger">{error}</Text>}
                    <Form.Item
                        name="mobile"
                        rules={[
                            {
                                required: true,
                                message: "Please input your mobile number!",
                            },
                            // {
                            //     type: "email",
                            //     message: "Invalid email",
                            // },
                        ]}
                    // hasFeedback
                    >
                        <Input
                            // prefix={
                            //     <UserOutlined className="site-form-item-icon" />
                            // }
                            placeholder="Mobile number"
                            size="large"
                            addonBefore="+91"
                            style={
                                {
                                    // backgroundColor: "transparent",
                                }
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ]}
                    // hasFeedback
                    >
                        <Input
                            // prefix={
                            //     <LockOutlined className="site-form-item-icon" />
                            // }
                            type="password"
                            placeholder="Password"
                            size="large"
                        />
                    </Form.Item>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            // justifyContent: "space-between",
                            padding: "0px 0 10px 0",
                            marginTop: "-5px"
                        }}
                    >
                        {/* <Form.Item
                            name="remember"
                            valuePropName="checked"
                            noStyle
                        >
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item> */}

                        <Link to="/forgot_password">
                            <span style={{ float: "right" }}>
                                Forgot password
                            </span>
                        </Link>
                    </div>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                            // size="large"
                            loading={loadingLogin || loadingCU}
                        >
                            {loadingLogin || loadingCU
                                ? "Signing in"
                                : "Sign in"}
                        </Button>
                    </Form.Item>

                    <div
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        New here? <Link to="/register">Create an account</Link>
                    </div>
                    {/* </Form.Item> */}
                </Form>
            </AutoLogin>
        </CenterCard2Part>
    );
}

export default LoginNew;
