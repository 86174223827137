import React, { useEffect, useState } from "react";
import { useItemsFine } from "../../actions/app/items";
import log from "cslog";
import PreviewTemplate from "./PreviewTemplate";
import { Modal } from "antd";
import LoadingBox from "../../common/LoadingBox";
import useMedia from "react-use/lib/useMedia";
import { shuffle } from "../../_helpers/array";

export const PreviewTemplateListStatic = ({ template_list, selected_template_index, post_idea, me, filter, open, setOpen, enable = false }) => {
    const [selected_index, setSelectedIndex] = React.useState(null);
    const [list, setList] = useState([]);
    const isMobile = useMedia("(max-width: 700px)");

    log.d(list, "list in PreviewTemplateListStatic")

    useEffect(() => {
        if (template_list.length > 0) {
            setSelectedIndex(selected_template_index || 0);
            setList(template_list);
        }
    }, [template_list, selected_template_index])


    if (template_list?.length === 0) {
        return <Modal open={open} footer={null} onCancel={() => setOpen(false)} >
            <p>No creation found for this</p>
        </Modal>
    }

    return (
        <div>
            {/* <h1>PreviewTemplateList</h1>
            {isSuccess && list.length > 0 && list.map(item => {
                return <div key={item.id}>
                    <h4>{item.title}</h4>
                </div>
            })} */}
            {list.length > 0 && list[selected_index] &&
                <PreviewTemplate
                    // key={selected_index}
                    // trigger={trigger}
                    open={open}
                    setOpen={setOpen}
                    is_template={true}
                    slug_field="id"
                    enable_nav={true}
                    isMobile={isMobile}
                    item={list[selected_index]}
                    // enable_prev={selected_index > 0}
                    // enable_next={selected_index < list.length - 1}
                    // onPrevClick={() => setSelectedIndex(selected_index - 1)}
                    // onNextClick={() => setSelectedIndex(selected_index + 1)}
                    enable_redesign={list.length > 1}
                    onRedeisgnClick={() => {
                        if (selected_index > list.length - 2) {
                            setSelectedIndex(0);
                        } else {
                            setSelectedIndex(selected_index + 1);
                        }
                    }}
                    me={me}
                    post_idea={post_idea}
                />}
        </div>
    );
}