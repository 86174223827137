import { ArrowRightOutlined, LockOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Row, Select } from "antd";
import log from "cslog";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useMedia from "react-use/lib/useMedia";
import { getPublicImageURL } from "../_helpers/image";
import { queryClient } from "../_helpers/query";
import CenterCard2Part from "../_layouts/CenterCard2Part";
import { useCurrentUser, useRegister, useSettings } from "../actions/auth";

const { Option } = Select;

const VerifyOTP = () => {
    const isMobile = useMedia("(max-width: 700px)");
    const [mobile_verified, setMobileVerified] = useState(false);


    const { isSuccess, isLoading, data: dataMe } = useCurrentUser();

    // log.d(successProject, "success project");
    // log.d(dataProject, "data project");

    log.d(dataMe, "dataMe in VerifyOTP")

    useEffect(() => {
        queryClient.invalidateQueries("user");
    }, []);



    return (
        <CenterCard2Part
            // title="Welcome to Qureal"
            title="Verify your mobile number"
            // logo={getPublicImageURL(dataProject.project_logo)}
            width={isMobile ? 350 : 500}
            center={true}
        >
            {/* {mutation?.errors?.length > 0 &&
                mutation.errors.map((item) => (
                    <div key={item.message}>
                        <Alert type="error" showIcon message={item.message} />
                        <br></br>
                    </div>
                ))} */}
            <MobileVerify />
        </CenterCard2Part>
    );
};

export default VerifyOTP;


function MobileVerify({ setMobileVerified }) {
    const [mobile, setMobile] = useState("");
    const [step, setStep] = useState("mobile_input")

    const sendOTP = () => {
        setStep("otp_input")
    }

    const verifyOTP = () => {
        alert("Verifying OTP")
        setMobileVerified(true)
    }

    return <div>
        {step === "mobile_input" && <MobileInput mobile={mobile} setMobile={setMobile} onSubmit={sendOTP} />}
        {step === "otp_input" && <OTPInput verifyOTP={verifyOTP} mobile={mobile} />}
        <Link to="/login">
            <div
                style={{
                    textAlign: "center",
                    paddingTop: "15px",
                    paddingBottom: "-20px",
                }}
            >
                Already have account? Login
            </div>
        </Link>
    </div>
}


function MobileInput({ mobile, setMobile, onSubmit }) {
    const [form] = Form.useForm();

    useEffect(() => {
        let mobile = localStorage.getItem("qureal_mobile")
        if (mobile) {
            if (mobile?.length == 12 && mobile.startsWith("91")) {
                mobile = mobile.substring(2)
            }
            setMobile(mobile)
            form.setFieldsValue({
                mobile: mobile,
            });
        }
    }, [])


    return <Form
        form={form}
        layout="vertical" style={{ maxWidth: "450px" }}
        onFinish={(values) => {
            // alert("Submitting: " + JSON.stringify(values));
            log.d(values, "Form Values");
            // onSubmit();
        }}
        requiredMark={false}
        initialValues={{ mobile }}
    >

        <Form.Item
            // label="Moble Number"
            name="mobile"
            // rules={[{ required: true, message: 'Please enter your mobile number!' }]}
            validateTrigger="onSubmit"
            hasFeedback={true}
            rules={[
                {
                    required: true,
                    message: 'Please enter your mobile number',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (validateIndianMobileNumber(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Please enter valid mobile number'));
                    },
                }),
            ]}
        >
            <Input
                addonBefore="+91"
                placeholder="Mobile number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                // type="number"
                size="large"
            />
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType="submit" icon={<ArrowRightOutlined />} iconPosition="end"
                style={{ width: "100%" }}
            >Send OTP</Button>
        </Form.Item>
    </Form>
}


function OTPInput({ verifyOTP, mobile }) {
    return <Form
        layout="vertical" style={{ maxWidth: "450px" }}
        onFinish={(values) => {
            alert("Submitting: " + JSON.stringify(values));
        }}
        requiredMark={false}
    >
        <p>OTP sent to <span style={{ color: "blue" }}>{mobile}</span></p>
        <Form.Item
            label="Enter OTP"
            name="otp"
            rules={[{ required: true, message: 'Please enter your OTP!' }]}
        // style={{ textAlign: "center" }}
        >
            <Input.OTP length={4} size="large" onChange={(value) => verifyOTP(value)} />
        </Form.Item>

        <Form.Item>
            <Button type="primary" htmlType="submit" icon={<LockOutlined />} iconPosition="end"
                style={{ width: "100%" }}
            >Submit</Button>
        </Form.Item>
    </Form>
}


function RestInfo({ register, mutation }) {
    return <Form
        name="normal_login"
        style={{
            maxWidth: "450px",
        }}
        initialValues={{ remember: true }}
        onFinish={register}
    >
        <p style={{ color: "green" }}>OTP verified successfully</p>
        <Row gutter={[10, 10]}>
            {/* <Col span={8}> */}
            <Col xs={24} sm={12}>
                <Form.Item
                    name="first_name"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your first name",
                        },
                    ]}
                    hasFeedback
                >
                    <Input placeholder="First Name" size="large" />
                </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
                <Form.Item
                    name="last_name"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your last name",
                        },
                    ]}
                    hasFeedback
                >
                    <Input placeholder="Last Name" size="large" />
                </Form.Item>
            </Col>
        </Row>
        {/* <Form.Item
            name="email"
            rules={[
                {
                    required: true,
                    message: "Please input your Email!",
                },
                {
                    type: "email",
                    message: "Invalid email",
                },
            ]}
            hasFeedback
        >
            <Input
                prefix={
                    <UserOutlined className="site-form-item-icon" />
                }
                placeholder="Email"
                size="large"
            />
        </Form.Item> */}


        <Form.Item
            name="password"
            rules={[
                {
                    required: true,
                    message: "Please input your Password!",
                },
            ]}
            hasFeedback
        >
            <Input
                prefix={
                    <LockOutlined className="site-form-item-icon" />
                }
                type="password"
                placeholder="Password"
                size="large"
            />
        </Form.Item>

        <Form.Item
            name="password2"
            dependencies={["password"]}
            hasFeedback
            rules={[
                {
                    required: true,
                    message: "Please confirm your Password!",
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (
                            !value ||
                            getFieldValue("password") === value
                        ) {
                            return Promise.resolve();
                        }
                        return Promise.reject(
                            new Error("Passwords do not match!")
                        );
                    },
                }),
            ]}
        >
            <Input
                prefix={
                    <LockOutlined className="site-form-item-icon" />
                }
                type="password"
                placeholder="Confirm Password"
                size="large"
            />
        </Form.Item>


        {/* <Form.Item> */}
        <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            // size="large"
            loading={mutation.isLoading}
        >
            Preceed
        </Button>


        {/* Or <a href="/">register now!</a> */}

        {/* </Form.Item> */}
    </Form>
}



function validateIndianMobileNumber(number) {
    // Regular expression to match a 10-digit number starting with 6, 7, 8, or 9
    const mobileNumberPattern = /^[6-9]\d{9}$/;

    // Check if the input matches the pattern
    if (mobileNumberPattern.test(number)) {
        return true; // Valid mobile number
    } else {
        return false; // Invalid mobile number
    }
}