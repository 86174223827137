import React, { Fragment, useEffect, useState } from "react";
import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import log from "cslog";
import { useUploadFile } from "../../actions/files";
import { BASE_URL } from "../../constants";
import { restoreAccessToken } from "../../_helpers/auth";
import { getImageFileURL, getPublicImageURL } from "../../_helpers/image";
import { useBGRemove } from "../../_actions/bot";
import { useSingleItem } from "../../_actions/app/items";
import ImagePicker from "../../common/imagepicker/ImagePicker";

function getImageURL(imageId) {
    return `${BASE_URL}assets/${imageId}?key=system-medium-cover&access_token=${restoreAccessToken()}`;
}



function PhotoButton({ field, value, onChange, width = "150px", height = "150px", objectFit = "cover", show_placeholder = false, background = "white", enable_remove_bg = false }) {
    const [photo, setPhoto] = useState({ id: value });
    const {
        isSuccess: successFullPhotoData,
        isLoading: loadingFullPhotoData,
        data: fullPhotoData
    } = useSingleItem("directus_files", `/${value}`, (value !== undefined && enable_remove_bg));

    const fileMutation = useUploadFile((data, variables, context) => {
        log.d(data, "Success Data");
        setPhoto(data);
        onChange(data.id);
    });
    const mutationRemoveBG = useBGRemove((new_photo) => {
        log.d(new_photo, "Photo with bg removed");
        if (new_photo?.data?.success) {
            const photo = new_photo?.data?.file;
            log.d(photo, "Now photo success")
            setPhoto(photo);
            onChange(photo.id);
        }
    })

    // newly added
    useEffect(() => {
        setPhoto({ id: value })
    }, [value])

    log.d(value, "Value in PhotoButton");
    log.d(fullPhotoData, "fullPhotoData in PhotoButton")

    const onFile = (eve) => {
        console.log("Got Image");
        const file = eve.target.files[0];
        if (file) {
            if (typeof file === "object" && file !== null) {
                const formData = new FormData();
                // formData.append("title", "Some title")
                formData.append("file", file);
                fileMutation.mutate(formData);
            }
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px"
            }}
        >
            <div style={{
                display: "flex",
                gap: "5px"
            }}>
                <Button
                    loading={fileMutation.isLoading}
                    type="primary"
                    ghost
                    icon={<UploadOutlined />}
                    onClick={(eve) => {
                        eve.preventDefault();
                        document
                            .getElementById(
                                `photo-${field.collection}-${field.field}`
                            )
                            .click();
                    }}
                >
                    {fileMutation.isLoading
                        ? "Uploading..."
                        : fileMutation.isSuccess
                            ? "Change"
                            : "Upload"}
                </Button>
                <ImagePicker
                    trigger={<Button>Choose</Button>}
                    onChange={(image) => {
                        log.d(image, "Image from Image Picker");
                        setPhoto(image);
                        onChange(image.id);
                    }}
                />
                {enable_remove_bg && successFullPhotoData && <Button
                    onClick={() => {
                        mutationRemoveBG.mutate({
                            filename: fullPhotoData?.filename_disk,
                        });
                    }}
                    loading={mutationRemoveBG.isLoading}
                    disabled={mutationRemoveBG.isLoading || !successFullPhotoData || fullPhotoData?.bg_removed}
                >Remove background</Button>}
            </div>
            {photo?.id && (
                <img
                    src={getPublicImageURL(photo.id)}
                    alt="preview"
                    style={{
                        width: width,
                        height: height,
                        borderRadius: "4px",
                        border: "1px solid grey",
                        objectFit: objectFit,
                        background: background
                    }}
                />
            )}
            {!photo?.id && show_placeholder && (
                <div
                    style={{
                        width: width,
                        maxWidth: width,
                        height: height,
                        borderRadius: "4px",
                        border: "1px dashed grey",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "grey",
                        textAlign: "center",
                        background: background
                    }}
                >
                    No image selected
                </div>
            )}
            <input
                type="file"
                name="photo"
                onChange={onFile}
                id={`photo-${field.collection}-${field.field}`}
                hidden
                style={{
                    display: "none",
                }}
            />
        </div>
    );
}

export default PhotoButton;

// Ant design form custom field
// https://medium.com/swlh/use-custom-and-third-party-react-form-components-with-ant-design-and-typescript-2732e7849aee
