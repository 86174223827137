import { Button, Popover } from "antd";
import React, { useState } from "react";
import { useItemsFine } from "../../_actions/app/items";
import { getImageFileURL, getPublicImageURL } from "../../_helpers/image";
import { BulbOutlined, UserOutlined } from "@ant-design/icons";
import styles from "./styles/AIModelPicker.module.css";

function AIModelPicker({ input_id, input_data, onChange }) {
    const [open, setOpen] = useState(false);
    const { isLoading, isSuccess, data: dataModels } = useItemsFine("qdb_ai_model", `fields=id,name,feature_image`, open);


    return <div>
        {/* <p>{JSON.stringify(input_data)}</p> */}
        <Popover
            content={<div>
                {isSuccess && dataModels?.length > 0 && <div className={styles.wrapper}>
                    {dataModels.map((item) => {
                        return <img
                            key={item.id}
                            src={getPublicImageURL(item.feature_image)}
                            onClick={() => {
                                onChange({
                                    model: item.id
                                });
                                setOpen(false);
                            }}
                            className={styles.item}
                        />
                    })}
                </div>}
            </div>}
            title="Select model"
            trigger="click"
            open={open}
            onOpenChange={setOpen}
        >
            <Button icon={<UserOutlined />}>Change Model</Button>
        </Popover>
    </div>;
}

export default AIModelPicker;