import { BulbOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from 'antd';
import startCase from "lodash/startCase";
import React, { useEffect } from "react";
import PhotoButton from "../../qforms/standard_elements/PhotoButton";
import log from "cslog"

const { TextArea } = Input;


const SHOW_PREFILLED_FIELDS = false



export function UserInputFormOne({ fields, prefilled_values, onSubmit }) {
    const [form] = Form.useForm();

    return <Form
        form={form}
        layout="vertical"
        initialValues={prefilled_values}
        onFinish={(values) => {
            log.d(values, "Submitted values")
            onSubmit(values);
        }}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        {Object.keys(fields).map(field_id => {
            const f = fields[field_id];
            log.d(f, "Next field")
            const itype = f.itype || "text"
            if (!SHOW_PREFILLED_FIELDS) {
                if (prefilled_values[field_id]) {
                    return <Form.Item name={field_id} hidden={true}>
                        <Input />
                    </Form.Item>
                }
            }


            if (itype === "img") {
                return <Form.Item name={field_id} label={startCase(field_id)}>
                    {/* <label>{startCase(field_id)}</label> */}
                    <PhotoButton
                        field={{
                            collection: "qqq",
                            field: field_id,
                        }}
                        width="150px"
                        height="150px"
                        objectFit="contain"
                        show_placeholder={true}
                        background="white"
                        enable_remove_bg={false}
                    />
                </Form.Item>
            }
            return <Form.Item name={field_id} label={startCase(field_id)}>
                {/* <label>{startCase(field_id)}</label> */}
                {/* <p>{JSON.stringify(f)}</p> */}
                <Input />
            </Form.Item>
        })
        }


        <Form.Item>
            <Space>
                <Button type="primary" htmlType="submit"
                    // loading={loadingAI}
                    icon={<BulbOutlined />}>
                    Proceed
                </Button>
            </Space>
        </Form.Item>
    </Form>

}


function UserInputFormAll({ enable, data }) {
    const [form] = Form.useForm();

    log.d(data, "Data in UserInputForm")


    useEffect(() => {

    }, [data])




    if (!enable) {
        return <></>
    }


    return <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {

        }}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        {Object.keys(data).map(data_block_id => {
            const vals = data[data_block_id];
            const fields = vals.user_input_fields;
            let prefilled_values = {};
            if (vals?.extracted_user_inputs?.success) {
                prefilled_values = vals?.extracted_user_inputs?.data
            }
            return <UserInputFormOne fields={fields} prefilled_values={prefilled_values} />
        })}


        <Form.Item>
            <Space>
                <Button type="primary" htmlType="submit"
                    // loading={loadingAI}
                    icon={<BulbOutlined />}>
                    Generate
                </Button>
                <Button htmlType="button"
                // onClick={onFill}
                >
                    Reset
                </Button>
            </Space>
        </Form.Item>
    </Form>
}

export default UserInputFormAll;