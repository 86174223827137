import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import AllCollections from "./AllCollections";
import styles from "./styles/ContentCalender.module.css";


function MyCollections({ me = {} }) {
    const history = useHistory();

    return (
        <QurealSection header={<SectionHeader
            heading="My Collections"
        >
            <Button type="primary" onClick={() => {
                history.push(`/settings/collections/create`, {
                    from: history.location.pathname,
                });
            }}>Create Collection</Button>
        </SectionHeader>}>
            {/* <PreviewTemplateList
                    tags={["gandhi"]}
                    enable={true}
                    open={true}
                    setOpen={setOpen}
            /> */}
            <div className={styles.wrapper}>
                <AllCollections />
            </div>
            {/* {selected_card && <PreviewTemplateList
                enable={open}
                filter={selected_card?.filter ? JSON.stringify(selected_card.filter) : null}
                open={open}
                setOpen={setOpen}
                me={me}
            />} */}
        </QurealSection>
    );
}

export default MyCollections;
