import React, { useEffect, useState } from "react";
import { Button, Modal, Pagination } from "antd";
import { useFiles } from "../../_actions/app/files";
import log from "cslog";
import styles from "./styles/ImagePicker.module.css";
import { getImageFileURL } from "../../_helpers/image";
import classNames from "classnames";


function ImagePicker({ trigger = <Button> Choose</Button>, per_page = 25, onChange }) {
    const [open, setOpen] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const { isSuccess, isLoading, data } = useFiles(`offset=${(page - 1) * 20}&limit=${per_page}`, open);
    const [selected_img, setSelectedImg] = useState(null);

    log.d(data, "Files Data");

    useEffect(() => {
        setPage(1);
        setSelectedImg(null);
    }, [open])

    useEffect(() => {
        if (isSuccess) {
            const meta = data?.meta;
            setTotal(meta?.filter_count || 0)
        }
    }, [isSuccess, data])

    return <>
        <div onClick={() => setOpen(true)}>{trigger}</div>

        <Modal
            title="Select Image"
            open={open}
            onOk={() => {
                onChange(selected_img);
                setOpen(false);
            }}
            okButtonProps={{
                disabled: !selected_img
            }}
            okText="Preceed"
            onCancel={() => setOpen(false)}
            width={"70%"}
        >
            <div className={styles.gallery_wrapper}>
                {isSuccess && data?.data?.map(item => {
                    return <div className={classNames({
                        [styles.item]: true,
                        [styles.selected]: selected_img?.id === item?.id
                    })}>
                        <img className={styles.photo} src={getImageFileURL(item?.filename_disk)}
                            onClick={() => setSelectedImg(item)}
                        />
                    </div>
                })}
            </div>


            {isSuccess && <Pagination
                current={page}
                onChange={(page) => {
                    log.d(page, "Page");
                    setPage(page);
                }}
                total={total}
                style={{
                    marginTop: "20px"
                }}
                showSizeChanger={false}
            />}
        </Modal>
    </>
}

export default ImagePicker;