import React, { Fragment } from "react";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Segmented, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";

import log from "cslog"
import CreationsPicker from "./CreationsPicker";
import { useCreateItem, useUpdateItem } from "../_actions/mutations/items";
import RelationFieldStanard from "../qforms/standard_elements/RelationFieldStandard";


const field_style = { maxWidth: "600px" }

function CollectionForm({ id, initial_values = {} }) {
    const [form] = Form.useForm();
    const mutationCreate = useCreateItem("qdb_creation_collection");
    const mutationUpdate = useUpdateItem("qdb_creation_collection", id, () => { });

    const collection_type = Form.useWatch("type", form);
    const preset = Form.useWatch("preset", form);

    const is_update = id !== undefined;

    const prepared_initial_values = prepareInitialValue(initial_values);


    const createCollection = (values) => {
        const creations_new = [];
        if (values.creations) {
            values.creations.forEach((item) => {
                creations_new.push({
                    collection: item.collection,
                    item: item.id,
                });
            });
        }



        const out = {
            ...values,
            type: "manual",
            creations: creations_new
        }

        if (collection_type === "automated") {
            out["type"] = "automated";
            out["data"] = {
                preset: values?.preset,
                primary_tag: values?.primary_tag
            }

        }

        delete out["preset"];
        delete out["primary_tag"];

        log.d(out, "out to create collection");
        if (is_update) {
            // mutationUpdate.mutate(out);
        } else {
            mutationCreate.mutate(out);
        }
    }





    return <Form
        layout="vertical"
        form={form}
        // onFinish={addOrganization}
        initialValues={{
            // ...initial_values,
            type: "manual",
            ...prepared_initial_values
        }}
        onFinish={createCollection}
    >
        <Form.Item label="Name" name="name" style={field_style}>
            <Input />
        </Form.Item>

        <Form.Item
            label="Description"
            name="description"
            style={field_style}
        >
            <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Collection type" name="type" style={field_style}>
            <Segmented options={[
                { label: "Manual", value: "manual" },
                { label: "Automated", value: "automated" }
            ]} />
        </Form.Item>

        {collection_type === "automated" && <AutomatedCollectionForm
            preset={preset}
        />}

        {collection_type === "manual" && <Form.Item
            label="Add Templates and Creations"
            name="creations"
        >
            <CreationsPicker />
        </Form.Item>}

        <Form.Item>
            <Button type="primary" htmlType="submit" loading={mutationCreate.isLoading}>
                Save Collection
            </Button>
        </Form.Item>
    </Form>
}

export default CollectionForm;


function prepareInitialValue(values) {
    const out = { ...values };
    if (values.creations) {
        out.creations = values.creations.map((item) => {
            return item.item;
        })
    }
    return out
}

function AutomatedCollectionForm({ preset }) {

    return <Fragment>
        <Form.Item label="Used preset" name="preset" style={field_style}>
            <RelationFieldStanard
                // key={render_key}
                field={{
                    foreign_key_table: "qdb_creation_schema",
                    foreign_key_column: "id",
                    junction_field: "qdb_tag_id",
                    many_table_pk: "id",
                    template: "{{name}}",
                    type: "relationO2M",
                }}
                fieldString="&limit=-1"
                // value={tags}
                onChange={(val) => {
                    // setTags(val);
                }}
                many={false}
                style={{
                    width: "100%"
                }}
                additional_options={[]}
            />
        </Form.Item>

        {preset === "0e0c5b74-e8d5-4237-be1e-8e4ae3dded98" &&
            <Form.Item label="Primary Tag" name="primary_tag" style={field_style}>
                <RelationFieldStanard
                    // key={render_key}
                    field={{
                        foreign_key_table: "qdb_tag",
                        foreign_key_column: "id",
                        junction_field: "qdb_tag_id",
                        many_table_pk: "id",
                        template: "{{name}}",
                        type: "relationO2M",
                    }}
                    fieldString="filter[type][_eq]=primary&limit=-1"
                    // value={tags}
                    onChange={(val) => {
                        // setTags(val);
                    }}
                    many={false}
                    style={{
                        width: "100%"
                    }}
                    additional_options={[]}
                />
            </Form.Item>}
    </Fragment>
}