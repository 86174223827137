import { startCase } from "lodash";
import MyCreations from "./MyCreations";
import TemplatesBar from "./TemplatesBar";
import styles from "./styles/CreationDisplayArea.module.css";
import React, { useState } from "react";
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import { Button, Form, Tabs } from 'antd';
import BrowseTemplates from "./BrowseTemplates";
import RelationFieldStanard from "../qforms/standard_elements/RelationFieldStandard";

const TITLE_MAPPING = {
    all_creation: "All Creations",
    singlepage: "My Social Media Posts",
    website: "My Websites",
    magazine: "My Magazines"
}

const TYPE_FILTERS = {
    singlepage: {
        typebase: {
            _eq: "singlepage"
        }
    },
    magazine: {
        typebase: {
            _eq: "card"
        }
    },
    website: {
        layout: {
            _eq: "responsive"
        }
    },
    all_creation: {

    }
}


function CreationDisplayArea({ type, me }) {
    return (
        <div className={styles.wrapper}>
            {/* <TemplatesBar type={type} /> */}
            <Tabs
                defaultActiveKey="2"
                items={[
                    {
                        key: "my_creations",
                        label: `My ${startCase(type)}`,
                        children: <MyCreations type={type} me={me} />,
                        icon: <AppleOutlined />,
                    },
                    {
                        key: "Explore Ideas",
                        label: "Explore Ideas",
                        children: <BrowseTemplates
                            creation_type={type}
                            show_as_row={false}
                            filter={TYPE_FILTERS[type] || {}}
                            // heading={TITLE_MAPPING[type]}
                            me={me}
                        />,
                        icon: <AndroidOutlined />,
                    },
                    ...(me?.irole === "reviewer" ? [{
                        key: "review",
                        label: "Review",
                        children: <OthersCreations type={type} me={me} />,
                        icon: <AndroidOutlined />,
                    }] : [])
                ]}

            />
            {/* <h3>My {startCase(type)}s</h3> */}
        </div>
    );
}

export default CreationDisplayArea;


function OthersCreations({ type, me }) {
    const [user, setUser] = useState("$CURRENT_USER")

    return <div>
        <Form layout="vertical">
            <Form.Item label="Select User" name="act_as"
                style={{ maxWidth: "300px" }}
            >
                <RelationFieldStanard
                    field={{
                        foreign_key_table: "directus_users",
                        foreign_key_column: "id",
                        junction_field: "qdb_tag_id",
                        many_table_pk: "id",
                        template: "{{first_name}} {{last_name}}",
                        type: "relationO2M",
                    }}
                    fieldString="&limit=-1&filter[irole][_eq]=designer"
                    value={user}
                    onChange={(val) => {
                        setUser(val)
                    }}
                    many={false}
                    style={{
                        width: "100%"
                    }}
                    additional_options={[]}
                />
            </Form.Item>
        </Form>
        <MyCreations type={type} me={me} user={user} />
    </div>
}