import { LockOutlined, UserOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Row, Select } from "antd";
import log from "cslog";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useMedia from "react-use/lib/useMedia";
import { useCurrentUser, useLogout, useMe, useRegister, useResendOTP, useSettings, useUpdateMe, useVerifyOTP } from "../actions/auth";
import { USER_DEFAULT_ROLE } from "../constants";
import { history } from "../_helpers/history";
import { getPublicImageURL } from "../_helpers/image";
import { queryClient } from "../_helpers/query";
import CenterCard from "../_layouts/CenterCard";
import FacebookLoginButton from "./FacebookLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";
import CenterCard2Part from "../_layouts/CenterCard2Part";
import { useRegisterNew } from "../actions/auth_new";
import { diffTillNow, getTime, parseDate } from "../_helpers/app/dt";
import QUREAL_LOGO from "../media/imgs/0_logo_square.png";

const { Option } = Select;

const OTPScreen = () => {
    const isMobile = useMedia("(max-width: 700px)");
    const [mobile_verified, setMobileVerified] = useState(false);
    const { isSuccess: successMe, data: dataMe, isError: errorMe } = useMe(true);
    const { loading: loadingLogout, success: successLogout, logout } = useLogout();
    const [err_msg, setErr_msg] = useState(null);

    const mutationVerifyOTP = useVerifyOTP(result => {
        console.log("OTP Verified success");
        log.d(result, "OTP verified result");

        queryClient.invalidateQueries("user");
        if (result?.type === "success") {
            log.p("Pushing to home")
            history.push("/");
        } else if (result?.type === "error") {
            setErr_msg(result?.message);
        }
    });
    const mutationResendOTP = useResendOTP();


    log.d(dataMe, "dataMe in OTPScreen");

    useEffect(() => {
        if (mutationVerifyOTP.isSuccess) {
            const result = mutationVerifyOTP.data;
            log.d(result, "OTP verified result");
            if (result?.data?.type === "success") {
                history.push("/homee");
            }
        }
    }, [mutationVerifyOTP])



    useEffect(() => {
        if (dataMe?.data?.mobile_verified) {
            log.p("pushing to login after dataMe confirmation")
            history.push("/");
        }
    }, [dataMe]);

    useEffect(() => {
        if (successMe && !errorMe && Object.keys(dataMe?.data).length < 2) {
            logout()
            // alert("Logout")
        }
    }, [dataMe, successMe])


    useEffect(() => {
        queryClient.invalidateQueries("user");
    }, []);


    useEffect(() => {
        if (successMe && dataMe?.data?.mobile && !dataMe?.data?.mobile_verified && !dataMe?.data?.last_otp_at) {
            alert("resending OTP")
            if (mutationResendOTP.isIdle) {
                mutationResendOTP.mutate({});
            }
        }
    }, [dataMe]);

    // useEffect(() => {
    //     if (mutation.isSuccess) {
    //         history.push("/login");
    //     }
    // }, [mutation]);

    const verifyOTP = (otp) => {
        const out = {
            mobile: dataMe?.data?.mobile,
            otp: otp,
        }

        log.d(out, "OTP Verify Values");
        mutationVerifyOTP.mutate(out);
        setErr_msg(null);
    };

    const resendOTP = () => {
        setErr_msg(null);
        mutationResendOTP.mutate({});
    }

    const last_otp_diff = dataMe?.data?.last_otp_at ? diffTillNow(dataMe?.data?.last_otp_at) : null;

    return (
        <CenterCard2Part
            // title="Welcome to Qureal"
            title="Verify your account"
            logo={QUREAL_LOGO}
            width={isMobile ? 350 : 500}
            center={true}
        >
            {mutationVerifyOTP?.errors?.length > 0 &&
                mutationVerifyOTP.errors.map((item) => (
                    <div key={item.message}>
                        <Alert type="error" showIcon message={item.message} />
                        <br></br>
                    </div>
                ))}

            <div>
                {/* <p>Last OTP at: {last_otp_diff}</p> */}
                {successMe && !dataMe?.data?.mobile && <MobileInput />}
                {dataMe?.data?.mobile && <OTPInput verifyOTP={verifyOTP} mobile={dataMe?.data?.mobile}
                    loading={mutationVerifyOTP.isLoading}
                    loadingResend={mutationResendOTP.isLoading}
                    resendOTP={resendOTP}
                    enableResend={!last_otp_diff || last_otp_diff > 120}
                />}
                {err_msg && <p style={{ color: "#f28d85" }}>{err_msg}</p>}
                <Link to="/login?nocheck=true">
                    <div
                        style={{
                            textAlign: "center",
                            paddingTop: "15px",
                            paddingBottom: "-20px",
                        }}
                    >
                        Already have account? Login
                    </div>
                </Link>
            </div>
        </CenterCard2Part>
    );
};

export default OTPScreen;


function MobileInput({ setMobile, mobile }) {
    const mutationUpdate = useUpdateMe();

    return <Form
        layout="vertical" style={{ maxWidth: "450px" }}
        onFinish={(values) => {
            const mobile = values?.mobile?.startsWith("91") ? values.mobile : "91" + values.mobile;
            mutationUpdate.mutate({
                mobile: mobile,
                // mobile_verified: false,
                // last_otp_at: null
            });
        }}
        requiredMark={false}
    >

        <Form.Item
            // label="Moble Number"
            name="mobile"
            // rules={[{ required: true, message: 'Please enter your mobile number!' }]}
            validateTrigger="onSubmit"
            hasFeedback={true}
            rules={[
                {
                    required: true,
                    message: 'Please enter your mobile number',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (validateIndianMobileNumber(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Please enter valid mobile number'));
                    },
                }),
            ]}
        >
            <Input
                addonBefore="+91"
                placeholder="Mobile number"
                type="number"
                size="large"
            />
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType="submit" icon={<LockOutlined />} iconPosition="end"
                style={{ width: "100%" }}
                loading={mutationUpdate.isLoading}
            >Submit</Button>
        </Form.Item>
    </Form>
}



function OTPInput({ verifyOTP, mobile, loading, loadingResend, resendOTP, enableResend }) {
    return <Form
        layout="vertical" style={{ maxWidth: "450px" }}
        onFinish={(values) => {
            // alert("Submitting: " + JSON.stringify(values));
            verifyOTP(values?.otp);
        }}
        requiredMark={false}
    >
        <p>OTP sent to <span style={{ color: "blue" }}>{mobile}</span></p>
        <Form.Item
            label="Enter OTP"
            name="otp"
            rules={[{ required: true, message: 'Please enter your OTP!' }]}
        // style={{ textAlign: "center" }}
        >
            <Input.OTP length={4} size="large" onChange={(value) => verifyOTP(value)} />
        </Form.Item>

        <Form.Item>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
            }}>
                <Button type="primary" htmlType="submit" icon={<LockOutlined />} iconPosition="end"
                    style={{ width: "100%" }}
                    loading={loading}
                >Submit</Button>
                <Button disabled={!enableResend} onClick={resendOTP}>Resend</Button>
            </div>
        </Form.Item>
    </Form>
}





function validateIndianMobileNumber(number) {
    // Regular expression to match a 10-digit number starting with 6, 7, 8, or 9
    const mobileNumberPattern = /^[6-9]\d{9}$/;

    // Check if the input matches the pattern
    if (mobileNumberPattern.test(number)) {
        return true; // Valid mobile number
    } else {
        return false; // Invalid mobile number
    }
}