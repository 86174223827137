import React, { useEffect, useState } from "react";
import log from "cslog";
import { Button, Checkbox, Col, Form, Input, Popover, Space, Row } from 'antd';
import OrgPicker from "./OrgPicker";
import RelationFieldStanard from "../../qforms/standard_elements/RelationFieldStandard";
import { BulbOutlined, ControlOutlined } from "@ant-design/icons";
import startCase from "lodash/startCase";
import { UserInputFormOne } from "./UserInputForm";
import { useFieldsDirect, useQdbFields } from "../../_actions/app/fields";
import { useItemsFine } from "../../_actions/app/items";
import AIModelPicker from "./AIModelPicker";

const { TextArea } = Input;


function TemplateAIInput({ inputs = [], used_schemas = [], user_inputs = {}, onAISubmit, me, creation_type, org, onOrgChange, person, onPersonChange, isMobile, loadingAI, prefill, setPrefill }) {
    const [schemas, setSchemas] = useState({})


    useEffect(() => {
        if (used_schemas.length > 0) {
            const out = {}
            used_schemas.forEach((schema) => {
                out[schema.qdb_creation_schema_id.id] = schema.qdb_creation_schema_id
            })
            setSchemas(out);
        }
    }, [used_schemas])

    const page_id = creation_type === "document" ? "home" : "frontSide";

    const pageInputs = inputs?.[page_id]

    log.d(schemas, "Schemas in PreviewTemplate");

    if (!pageInputs) {
        return <></>
    }
    return <div>
        {/* <h4>Customize</h4> */}
        <p>
            {Object.keys(pageInputs).map((input_id) => {
                const input = pageInputs[input_id]
                switch (input.block_type) {
                    case "qureal_database":
                        return <DBInput
                            input_id={input_id}
                            input_data={input?.data}
                            me={me}
                            isMobile={isMobile}
                            org={org}
                            onOrgChange={onOrgChange}
                            person={person}
                            onPersonChange={onPersonChange}
                            prefill={prefill?.[input_id]}
                            setPrefill={new_values => {
                                setPrefill({
                                    ...prefill,
                                    [input_id]: new_values || {}
                                })
                            }}
                        />
                    case "qureal_preset":
                        return <PresetInput
                            input_data={input?.data}
                            schemas={schemas}
                            onAISubmit={onAISubmit}
                            loadingAI={loadingAI}
                            user_inputs={user_inputs?.enable ? user_inputs?.data?.[input_id] : {}}
                            data_block_id={input_id}
                        />
                    case "ai_model":
                        return <AIModelPicker
                            input_id={input_id}
                            input_data={input?.data}
                            onChange={new_values => {
                                // alert(`New model: ${JSON.stringify(new_values)}`)
                                setPrefill({
                                    ...prefill,
                                    [input_id]: new_values
                                })
                            }}
                        />
                    default:
                        return <p className="q_gray">{input?.block_type} not supported yet.</p>
                }
            })}
        </p>
    </div>
}

export default TemplateAIInput;



function PresetInput({ input_data, schemas, user_inputs, onAISubmit, loadingAI, data_block_id }) {
    const [form] = Form.useForm();

    const applied_schema = schemas?.[input_data?.qureal_schema_id]

    log.d(user_inputs, "user_inputs in PresetInput")

    const user_input_fields = user_inputs?.user_input_fields || {};
    const prefilled_values = user_inputs?.extracted_user_inputs?.success ? user_inputs?.extracted_user_inputs?.data : {};
    const user_prompt = user_inputs?.user_prompt || "";

    log.d(prefilled_values, "prefilled_values in PresetInput")

    if (Object.keys(user_input_fields).length > 0) {
        return <UserInputFormOne
            fields={user_input_fields}
            prefilled_values={prefilled_values}
            onSubmit={(values) => {
                onAISubmit({
                    user_prompt: user_prompt,
                    user_filled_inputs: {
                        [data_block_id]: values
                    }
                })
            }}
            data_block_id={data_block_id}
        />
    }


    return <Form
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        onFinish={(values) => {
            if (values?.user_prompt?.length > 3) {
                onAISubmit(values)
            }
        }}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        {/* <p>INPUT: {JSON.stringify(input_data)}</p>
        <p>APPLIED_Schema: {JSON.stringify(applied_schema)}</p> */}
        <Form.Item
            name="user_prompt"
            label={applied_schema?.user_prompt_question || "Enter input"}
            rules={[
                // { required: true }, 
                { type: 'string', max: 200 }
            ]}
        >
            <TextArea rows={4} placeholder="Enter mesage" />
        </Form.Item>
        <Form.Item>
            <Space>
                <Button type="primary" htmlType="submit" loading={loadingAI} icon={<BulbOutlined />}>
                    Generate
                </Button>
                <Button htmlType="button"
                // onClick={onFill}
                >
                    Reset
                </Button>
            </Space>
        </Form.Item>
    </Form>

}


function DBInput({ input_id, input_data, me, org, onOrgChange, person, onPersonChange, isMobile, prefill, setPrefill }) {

    log.d(org, "org in DBInput")

    let form_field = null;
    if (input_id === "current_organization") {
        form_field = <OrgPicker
            me={me}
            org={org}
            onOrgChange={onOrgChange}
            isMobile={isMobile}
        />
    } else if (input_id === "current_person") {
        form_field = <RelationFieldStanard
            field={{
                foreign_key_table: "qdb_person",
                template: "{{full_name}}",
                foreign_key_column: "id"
            }}
            value={person}
            onChange={new_person => onPersonChange(new_person)}
            style={{
                width: 200,
                marginTop: "15px"
            }}
            showSearch={!isMobile}
        />
    }
    return <Form layout="vertical">

        <Form.Item name="organization" label="Organization">
            {/* {form_field} */}
            <div style={{
                display: "flex",
                gap: "10px"
            }}>
                {form_field}
                <CustomizeDBInput
                    input_id={input_id}
                    input_data={input_data}
                    value={prefill?.exclude_fields}
                    onChange={new_values => {
                        setPrefill({
                            ...prefill,
                            exclude_fields: new_values
                        })
                    }}
                />
            </div>
        </Form.Item>
    </Form>
}



function CustomizeDBInput({ input_data, value, onChange }) {
    const [open, setOpen] = useState(false);
    const { data, isSuccess } = useItemsFine("qdb_field", `fields=id,name,table&filter={"table":{"_eq":"${input_data?.table}"}}`, open);

    const table = input_data?.table;

    log.d(data, "Fields data")



    return <Popover title="Show / Hide fields" trigger="click" placement="bottomRight"
        open={open}
        onOpenChange={setOpen}
        content={<div>
            {isSuccess && <Checkbox.Group style={{ width: "400px" }}
                value={value}
                onChange={onChange}
            >
                <Row>
                    {data?.map((item) => {
                        return <Col key={item.id} span={12}>
                            <Checkbox value={item.id}>{startCase(item.name)}</Checkbox>
                        </Col>
                    })}
                </Row>
            </Checkbox.Group>}
        </div>}
    >
        <Button icon={<ControlOutlined />}></Button>
    </Popover>
}