import React, { useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Divider, Button, theme, Menu } from "antd";
import {
    LogoutOutlined,
    SettingOutlined,
    MenuOutlined,
    UserOutlined,
    ArrowDownOutlined,
} from "@ant-design/icons";
import { useLogout, useUpdateMe } from "../actions/auth";
import { history } from "../_helpers/history";
import { getImageURL, getPublicImageURL } from "../_helpers/image";
import log from "cslog";
import { useUpdateItem } from "../_actions/mutations/items";
import QurealSpotlight from "../creations/QurealSpotlight";
import { useItemsFine } from "../_actions/app/items";

const { useToken } = theme;

const TopBarOptions = ({ name, orgs, selected_org, isMobile, me = {} }) => {
    const { token } = useToken();
    const {
        logout,
        loading: loadingLogout,
        success: successLogout,
    } = useLogout();

    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        minWidth: "200px",
    };

    const menuStyle = {
        boxShadow: "none",
    };

    const items = [
        {
            key: "account_settings",
            label: "Account Settings",
            icon: <SettingOutlined />,
            disabled: false,
        },
        {
            key: "logout",
            label: "Logout",
            icon: <LogoutOutlined />,
            loading: loadingLogout,
        },
    ];

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px"
        }}>
            <QurealSpotlight me={me} />
            <OrgDropdown orgs={orgs} isMobile={isMobile} selected_org={selected_org} />
            <Dropdown
                menu={{
                    items,
                    onClick: ({ key }) => {
                        if (key === "logout") {
                            logout();
                        } else if (key === "account_settings") {
                            history.push("/settings/update-profile");
                        }
                    },
                }}
                dropdownRender={(menu) => (
                    <div style={contentStyle}>
                        {/* <Divider style={{ margin: 0 }} /> */}
                        <div
                            style={{
                                padding: "15px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                backgroundColor: "white",
                            }}
                        >
                            <a
                                target="_blank"
                                href="https://app.qureal.com/privacy_policy"
                            >
                                Privacy Policy
                            </a>
                            <a
                                target="_blank"
                                href="https://app.qureal.com/terms_and_conditions"
                            >
                                Terms & Conditions
                            </a>
                        </div>
                        {React.cloneElement(menu, { style: menuStyle })}
                    </div>
                )}
            >
                <div
                    onClick={(e) => e.preventDefault()}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "5px"
                    }}
                >
                    {" "}
                    <Space align="center">
                        {isMobile ? (
                            <UserOutlined />
                        ) : (
                            <Button icon={<UserOutlined />}>{name}</Button>
                        )}
                    </Space>
                </div>
            </Dropdown>
        </div>
    );
};

export default TopBarOptions;

function OrgDropdown({ orgs = [], selected_org, isMobile }) {
    const updateMeMutation = useUpdateMe();
    const { isSuccess, isLoading, data } = useItemsFine("qdb_organization", `filter[owner][_eq]=$CURRENT_USER&fields=id,name,is_personal,is_external,logo_square`, true);
    const [options, setOptions] = React.useState([]);


    useEffect(() => {
        if (isSuccess && data?.length > 0) {
            const out = orgs?.filter(item => !!item?.id).map(
                (org) => ({
                    key: org?.id,
                    label: org?.is_personal ? "Personal" : org.name,
                    icon: <img
                        src={getPublicImageURL(org.logo_square)}
                        alt="logo" style={{
                            height: "30px",
                            width: "30px",
                            marginRight: "10px",
                            objectFit: "contain"
                        }}
                    />,
                })
            );
            data.forEach((item) => {
                if (!out.find(i => i.key === item.id)) {
                    out.push({
                        label: item.is_personal ? "Personal" : item.name,
                        key: item.id,
                        icon: <img
                            src={getPublicImageURL(item.logo_square)}
                            alt="logo" style={{
                                height: "30px",
                                width: "30px",
                                marginRight: "10px",
                                objectFit: "contain"
                            }}
                        />,
                    })
                }
            })
            setOptions(out);
        }
    }, [isSuccess, data]);

    return <Dropdown
        menu={{
            items: options,
            // items: orgs.map(org => {
            //     if (org) {
            //         return {
            //             key: org?.id,
            //             label: org?.is_personal ? "Personal" : org.name,
            //             icon: <img
            //                 src={getPublicImageURL(org.logo_square)}
            //                 alt="logo" style={{
            //                     height: "30px",
            //                     width: "30px",
            //                     marginRight: "10px",
            //                     objectFit: "contain"
            //                 }}

            //             />,
            //         }
            //     }
            // }),
            onClick: ({ key }) => {
                // alert(`${key} selected`)
                updateMeMutation.mutate({
                    selected_org: key,
                })
            },
        }}
    // dropdownRender={(menu) => (<span>{menu}</span>)}
    >
        <div
            onClick={(e) => e.preventDefault()}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {" "}
            <Space align="center">
                {isMobile ? (
                    <Button
                        // icon={<UserOutlined />}
                        // iconPosition="end"
                        icon={<img
                            src={getPublicImageURL(selected_org?.logo)}
                            // src={getPublicImageURL(selected_org?.logo_square)}
                            alt="logo" style={{
                                // width: "20px",
                                width: "auto",
                                height: "20px",
                                margin: "-5px -5px",
                                objectFit: "contain"
                            }} />}
                        loading={updateMeMutation.isLoading}
                        style={{
                            marginTop: "20px"
                        }}
                    >
                        {/* {selected_org?.is_personal ? "Personal" : selected_org?.name} */}
                        <DownOutlined style={{ fontSize: ".9em", color: "#aaa" }} />
                    </Button>
                ) : (
                    <Button
                        // icon={<UserOutlined />}
                        // iconPosition="end"
                        icon={<img
                            // src={getPublicImageURL(selected_org?.logo)}
                            src={getPublicImageURL(selected_org?.logo_square)}
                            alt="logo" style={{
                                // width: "20px",
                                width: "auto",
                                height: "20px",
                                margin: "-5px 0",
                                objectFit: "contain"
                            }} />}
                        loading={updateMeMutation.isLoading}
                        style={{
                            marginTop: "20px"
                        }}
                    >
                        {selected_org?.is_personal ? "Personal" : selected_org?.name}
                        <DownOutlined style={{ fontSize: ".9em", color: "#aaa" }} />
                    </Button>
                )}
            </Space>
        </div>
    </Dropdown>
}


