import React from "react";
import { useItemsFine } from "../_actions/app/items";
import styles from "./styles/AllCollections.module.css";
import { useHistory } from "react-router-dom"
import BlankBox from "../common/BlankBox";

function AllCollections() {
    const { isSuccess, isLoading, data } = useItemsFine("qdb_creation_collection", "filter[user_created][_eq]=$CURRENT_USER", true);
    const history = useHistory();


    return <div>
        {isSuccess && data?.length > 0 && <div className={styles.wrapper}>
            {data?.map(item => {
                return <div key={item.id} className={styles.item} onClick={() => {
                    history.push(`/settings/collections/${item.id}/edit`, {
                        from: history.location.pathname,
                    });
                }}>
                    <h4>{item.name}</h4>
                    {item.type === "manual" && <p>Total {item.creations?.length} creations</p>}
                    {item.type === "automated" && <p>Automated</p>}
                </div>
            })}
        </div>}
        {isSuccess && data?.length === 0 && <div style={{ padding: "0 15px 15px 15px" }}>
            <p>No collection created</p>
        </div>}
    </div>
}


export default AllCollections;