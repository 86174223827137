import { Button } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import CollectionForm from "./CollectionForm";
import styles from "./styles/ContentCalender.module.css";
import { useSingleItem } from "../_actions/app/items";
import CollectionPreviewAndEmbed from "./CollectionPreviewAndEmbed";


function CollectionEdit({ me = {} }) {
    const history = useHistory();
    const { id } = useParams();
    const { isSuccess, isLoading, data } = useSingleItem(
        "qdb_creation_collection",
        `/${id}?fields=*,creations.*,creations.item.id,creations.item.preview,creations.item.title`,
        true
    )


    const is_update = id !== undefined;

    return (
        <QurealSection header={<SectionHeader
            heading={is_update ? "Edit Collection" : "Create Collection"}

        >
        </SectionHeader>}>
            {/* <pre>id: {id}</pre> */}
            <div className={styles.wrapper}>
                {(is_update ? isSuccess && data : true) && < CollectionForm
                    id={id}
                    initial_values={data}
                />}
            </div>
            {is_update && <div style={{ padding: "0 15px 15px 15px" }}>
                <CollectionPreviewAndEmbed id={id} />
            </div>}
        </QurealSection>
    );
}

export default CollectionEdit;
