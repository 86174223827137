import { useAtom } from "jotai";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useMe, useMyPermissions } from "../actions/auth";
import { authAtom } from "../states/auth";
import AppSplash from "./AppSplash";
import log from "cslog";
import OrgSetup from "../auth/OrgSetup";

function PrivateRoute({ component: Component, ...rest }) {
    const { isLoading, isFetching, isSuccess, data } = useMe();
    const { isLoading: loadingPermi, data: dataPermi } = useMyPermissions();
    const isLoggedIn = isSuccess && data?.data !== undefined;
    const isMobileVerified = isSuccess && data?.data?.mobile_verified;

    if (isLoading || loadingPermi || isFetching) {
        return <AppSplash msg="Verifying user..." />;
    }

    log.d(data, "Me in PrivateRoute");

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isLoggedIn) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    );
                }
                // else if (!isMobileVerified) {
                //     return (
                //         <Redirect
                //             to={{
                //                 pathname: "/verify_otp",
                //                 state: { from: props.location },
                //             }}
                //         />
                //     );
                // }
                return <Component {...props} />;
            }}
        />
    );
}

export default PrivateRoute;
