import React, { useState } from "react";
import { useItemsFine } from "../../_actions/app/items";
import styles from "./styles/CommentsBox.module.css";
import { Button, Collapse, Dropdown, Input, Select, Space } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useCreateItem, useUpdateItem } from "../../_actions/mutations/items";
import { DownOutlined, UserOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { TextArea } = Input;

function CommentsBox({ creation }) {
    const { isSuccess, isLoading, data } = useItemsFine("qdb_comment", `filter[creation][id][_eq]=${creation.id}&sort=-date_created&fields=*,user_created.first_name,user_created.last_name`, true);
    const [open_write, setOpenWrite] = useState(false);
    const [opened_panel, setOpenedPanel] = useState([]);

    return (
        <div>
            <Collapse
                activeKey={open_write ? ["comments"] : opened_panel}
                onChange={(data) => {
                    setOpenedPanel(data);
                    if (data?.length === 0) {
                        setOpenWrite(false);
                    }
                }}>
                <Panel header={`Comments (${data?.length})`} key="comments" extra={<PlusOutlined onClick={(e) => {
                    e.stopPropagation();
                    setOpenWrite(true);
                    setOpenedPanel(["comments"])
                }} />}>
                    {open_write ? <WriteComment
                        creation={creation}
                        onDone={() => {
                            setOpenWrite(false);
                            setOpenedPanel(["comments"]);
                        }}
                    /> :
                        isSuccess && data?.length > 0 && <div className={styles.wrapper}>
                            {data.map(item => <OneComment key={item.id} comment={item} />)}
                        </div>}

                    {!open_write && isSuccess && data?.length === 0 && <span>No comments yet</span>}
                </Panel>
            </Collapse>

            <div styles={{ height: "15px" }}>.</div>
        </div>
    )
}

export default CommentsBox;


function OneComment({ comment }) {
    const mutationUpdate = useUpdateItem("qdb_comment", comment.id, () => { });

    return (
        <div className={styles.item_wrapper}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                {comment.user_created && <p className={styles.user}>{comment.user_created.first_name} {comment.user_created.last_name}</p>}
                <StatusDropdown
                    value={comment.status}
                    onChange={newVal => {
                        mutationUpdate.mutate({
                            status: newVal
                        });
                    }}
                    loading={mutationUpdate.isLoading}
                />
            </div>
            {/* {comment.title && <h4>{comment.title}</h4>} */}
            {comment.content && <p>{comment.content}</p>}
            {/* <pre>{JSON.stringify(comment, null, 2)}</pre> */}
        </div>
    )
}

function WriteComment({ creation, onDone }) {
    const [content, setContent] = useState("");
    const [status, setStatus] = useState("none")
    const mutationCreate = useCreateItem("qdb_comment", onDone);

    return <div className={styles.write_wrapper}>
        <TextArea
            rows={4}
            placeholder="Comment content"
            onChange={(e) => setContent(e.target.value)}
            value={content}
        />
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Button type="primary" onClick={() => {
                mutationCreate.mutate({
                    content: content,
                    creation: creation?.id,
                    ...(status !== "none" && { status: status })
                })
            }}>Add Comment</Button>

            <StatusDropdown value={status} onChange={(val) => setStatus(val)} />
        </div>
    </div>
}

function StatusDropdown({ value, onChange, loading }) {
    return <Select
        defaultValue={value}
        value={value}
        allowClear
        style={{ width: 120 }}
        onChange={(val) => onChange(val)}
        loading={loading}
        options={[
            { label: "⚪ None", value: "none" },
            { label: "🟡 ToDo", value: "todo" },
            { label: "🔵 In Progress", value: "in_progress" },
            { label: "🟢 Done", value: "done" },
            { label: "🔴 On Hold", value: "on_hold" },
            { label: "📦 Archived", value: "archived" }
        ]}
    />
}