import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import calendar from "dayjs/plugin/calendar";

dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault("Asia/Kolkata");
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(calendar);


export const parseDate = (dt) => {
    return dayjs(dt);
    // return dayjs(dt).format("DD-MM-YYYY HH:mm:ss");
}


export const diffTillNow = (dt) => {
    return dayjs().diff(dayjs(dt), "second");
}

// format= r:relative, c:calender, d:datetime
export const getTime = (dt, format = "r") => {
    const ndt = dayjs(dt).utcOffset(0, true).local();
    switch (format) {
        case "r":
            return ndt.fromNow();
        case "rt":
            return ndt.toNow(true);
        case "d":
            return ndt.format("hh:mm A DD/MM/YYYY");
        case "od":
            return ndt.format("DD/MM/YYYY");
        case "c":
            return dayjs().calendar(ndt);
        default:
            return ndt.format("hh:mm A DD/MM/YYYY");
    }

    // return (
    //     dayjs(dt)
    // .add(330, "m")
    //         .utcOffset(0, true)
    //         .local()
    // .utc()
    // .fromNow()
    //         .format("hh:mm A DD/MM/YYYY")
};

// Formatting
// https://day.js.org/docs/en/display/format
