import { Col, Tabs } from "antd";
import React, { Suspense } from "react";
// import CollectionEmbedCode from "./CollectionEmbedCode";

const CollectionEmbedCode = React.lazy(() => import("./CollectionEmbedCode"))

function CollectionPreviewAndEmbed({ id }) {
    return <Tabs
        items={[
            {
                key: "preview",
                label: `Preview`,
                children: <CollectionPreview id={id} />,
            },
            {
                key: "embed",
                label: `Embed`,
                children: <Suspense fallback={<div>Loading...</div>}><CollectionEmbedCode id={id} /></Suspense>,
            },
        ]}
    />
}

export default CollectionPreviewAndEmbed


function CollectionPreview({ id }) {
    return <div style={{
        width: "100%",
        height: "50vw",
        outline: "1px solid #ccc",
        overflow: "hidden",
    }}>
        <iframe
            src={`https://show.qureal.com/embedlist/${id}`}
            width="100%"
            height="100%"
            style={{
                border: "none"
            }}
        />
    </div>
}

