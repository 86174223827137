


export function secondsElapsedFrom(date) {
    // Parse the input date into a Date object
    const givenDate = new Date(date);

    // Check if the given date is valid
    if (isNaN(givenDate.getTime())) {
        return -1;
    }

    // Get the current time
    const now = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = now - givenDate;

    // Convert milliseconds to seconds and return
    return Math.floor(differenceInMilliseconds / 1000);
}
