import React, { useEffect } from "react";
import { Select } from "antd";
import { useItemsFine } from "../../_actions/app/items";
import log from "cslog";


function OrgPicker({ org, onOrgChange, me, isMobile }) {
    const { isSuccess, isLoading, data } = useItemsFine("qdb_organization", `filter[owner][_eq]=$CURRENT_USER&fields=id,name,is_personal,is_external`, true);
    const [options, setOptions] = React.useState([]);

    useEffect(() => {
        if (isSuccess && data?.length > 0) {
            const out = me?.organizations?.filter(item => item.qdb_organization_id !== null).map(
                (item) => ({
                    label: item.qdb_organization_id?.is_personal ? "Personal" : item.qdb_organization_id?.name,
                    value: item.qdb_organization_id?.id
                })
            );
            data.forEach((item) => {
                if (!out.find(i => i.value === item.id)) {
                    out.push({
                        label: item.is_personal ? "Personal" : item.name,
                        value: item.id
                    })
                }
            })
            setOptions(out);
        }
    }, [isSuccess, data]);


    return <Select
        allowClear
        onChange={(val) => onOrgChange(val)}
        defaultValue={org}
        showSearch={!isMobile}
        options={options}
        // options={me?.organizations?.filter(item => item.qdb_organization_id !== null).map(
        //     (item) => ({
        //         label: item.qdb_organization_id?.is_personal ? "Personal" : item.qdb_organization_id?.name,
        //         value: item.qdb_organization_id?.id
        //     })
        // )}
        style={{
            width: 200,
            // marginTop: "15px"
        }}
    />
}

export default OrgPicker;